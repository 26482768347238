/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { LoginBody, LoginResponse } from '../model/models';
import { RegisterBody } from '../model/models';
import { RegisterResponse } from '../model/models';


import { Configuration }                                     from '../configuration';


export interface AuthControllerRegisterRequestParams {
    registerBody: RegisterBody;
}

export interface AuthControllerLoginRequestParams {
    loginBody: LoginBody;
}


export interface AuthServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
* @param requestParameters
     */
authControllerRegister(requestParameters: AuthControllerRegisterRequestParams, extraHttpRequestParams?: any): Observable<RegisterResponse>;
/**
 * 
 * 
* @param requestParameters
 */
authControllerLogin(requestParameters: AuthControllerLoginRequestParams, extraHttpRequestParams?: any): Promise<Observable<LoginResponse>>;

}
